<template>
  <vx-card title="Approval Customer Deposit Reversal">
    <div class="flex flex-col gap-3">
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Posting Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
          <div class="mt-4 flex justify-between">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="depositDate"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker">
                {{ globalDateFormat(picker.startDate) }} -
                {{ globalDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="
                () => {
                  this.depositDate = { startDate: null, endDate: null };
                }
              "
            ></vs-button>
            <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
          </div>
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Deposit Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
          <div class="mt-4 flex justify-between">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="collectDate"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker">
                {{ globalDateFormat(picker.startDate) }} -
                {{ globalDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
            <vs-button
              class="ml-4"
              color="danger"
              icon-pack="feather"
              icon="icon-x"
              @click="
                () => {
                  this.collectDate = { startDate: null, endDate: null };
                }
              "
            ></vs-button>
            <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
          </div>
        </div>
      </div>
      <vs-tabs>
        <vs-tab label="Waiting Reversal">
          <div class="con-tab-ejemplo">
            <waiting :depositDate="depositDate" :collectionDate="collectDate" />
          </div>
        </vs-tab>
        <vs-tab label="Reversed">
          <div class="con-tab-ejemplo">
            <reversed
              :depositDate="depositDate"
              :collectionDate="collectDate"
            />
          </div>
        </vs-tab>
        <!-- <vs-tab label="Rejected">
          <div class="con-tab-ejemplo">
            <rejected
              :depositDate="depositDate"
              :collectionDate="collectDate"
            />
          </div>
        </vs-tab> -->
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import Waiting from "./waiting/index.vue";
import Reversed from "./reversed/index.vue";
import Rejected from "./rejected/index.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    waiting: Waiting,
    reversed: Reversed,
    rejected: Rejected,
    DateRangePicker,
  },
  data() {
    return {
      depositDate: { startDate: null, endDate: null },
      collectDate: { startDate: null, endDate: null },
    };
  },
};
</script>
